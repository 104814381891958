<template>
  <div class="main-box">
    <a-form :model="formGeren" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="ec-box">

        <div class="content">
          <div class="con-item">
            <a-form-item label="省名称" name="province">
              <a-input v-model:value="formGeren.province" placeholder="请输入省名称"/>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="市名称" name="city">
              <a-input v-model:value="formGeren.city" placeholder="请输入市名称"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="区/县名称" name="area">
              <a-input v-model:value="formGeren.district" placeholder="请输入区/县名称"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="行政区划代码" name="areaCode">
              <a-input v-model:value="formGeren.areaCode" placeholder="请输入行政区划代码"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="金融机构全称" name="financeOrgFullName">
              <a-input v-model:value="formGeren.financeOrgFullName" placeholder="请输入金融机构全称"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="银行业金融机构法人编码" name="code">
              <a-input v-model:value="formGeren.code" placeholder="请输入银行业金融机构法人编码"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="注册企业数" name="registeredEnterpriseNum">
              <a-input v-model:value="formGeren.registeredEnterpriseNum" placeholder="请输入注册企业数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="授信企业数" name="creditEnterpriseNum">
              <a-input v-model:value="formGeren.creditEnterpriseNum" placeholder="请输入授信企业数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="获贷企业数" name="loanEnterpriseNum">
              <a-input v-model:value="formGeren.loanEnterpriseNum" placeholder="请输入获贷企业数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="获贷小微企业数" name="loanMicroEnterpriseNum">
              <a-input v-model:value="formGeren.loanMicroEnterpriseNum" placeholder="请输入获贷小微企业数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="授信总额" name="creditAmount">
              <a-input v-model:value="formGeren.creditAmount" placeholder="请输入授信总额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="放款总额" name="loanAmount">
              <a-input v-model:value="formGeren.loanAmount" placeholder="请输入放款总额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="普惠小微贷款总额" name="inclusiveMicroEnterpriseloanAmount">
              <a-input v-model:value="formGeren.inclusiveMicroEnterpriseloanAmount" placeholder="请输入普惠小微贷款总额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="放款笔数" name="loanNum">
              <a-input v-model:value="formGeren.loanNum" placeholder="请输入放款笔数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="信用放款总额" name="creditLoanAmount">
              <a-input v-model:value="formGeren.creditLoanAmount" placeholder="请输入信用放款总额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="信用放款笔数" name="creditLoanNum">
              <a-input v-model:value="formGeren.creditLoanNum" placeholder="请输入信用放款笔数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="融资需求金额" name="financingNeedsAmount">
              <a-input v-model:value="formGeren.financingNeedsAmount" placeholder="请输入融资需求金额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="融资需求笔数" name="financingNeedsNum">
              <a-input v-model:value="formGeren.financingNeedsNum" placeholder="请输入融资需求笔数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="放款平均利率" name="averageLendingRate">
              <a-input v-model:value="formGeren.averageLendingRate" placeholder="请输入放款平均利率"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="入驻金融机构数" name="settledInFinancialInstitutionNum">
              <a-input v-model:value="formGeren.settledInFinancialInstitutionNum" placeholder="请输入入驻金融机构数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="发布金融产品数" name="financialProductsNum">
              <a-input v-model:value="formGeren.financialProductsNum" placeholder="请输入发布金融产品数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="当月放款金额" name="monthLoanAmount">
              <a-input v-model:value="formGeren.monthLoanAmount" placeholder="请输入当月放款金额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="当月放款笔数" name="monthLoanNum">
              <a-input v-model:value="formGeren.monthLoanNum" placeholder="请输入当月放款笔数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="当月信用放款金额" name="monthCreditLoanAmount">
              <a-input v-model:value="formGeren.monthCreditLoanAmount" placeholder="请输入当月信用放款金额"></a-input>
            </a-form-item>
          </div>

          <div class="con-item">
            <a-form-item label="当月信用放款笔数" name="monthCreditLoanNum">
              <a-input v-model:value="formGeren.monthCreditLoanNum" placeholder="请输入当月信用放款笔数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="当月新增普惠小微贷款金额" name="monthCreditLoanEnterpriseNum">
              <a-input v-model:value="formGeren.monthCreditLoanEnterpriseNum" placeholder="请输入当月新增普惠小微贷款金额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="当月融资需求金额" name="monthFinancingNeedsAmount">
              <a-input v-model:value="formGeren.monthFinancingNeedsAmount" placeholder="请输入当月融资需求金额"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="当月融资需求笔数" name="monthFinancingNeedsNum">
              <a-input v-model:value="formGeren.monthFinancingNeedsNum" placeholder="请输入当月融资需求笔数"></a-input>
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="统计开始时间"  name="statisticsBeginTime">
              <a-date-picker  v-model:value="formGeren.statisticsBeginTime" format="yyyy-MM-DD HH:mm:ss" @change="onPickTime" />
            </a-form-item>
          </div>
          <div class="con-item">
            <a-form-item label="统 计 截 止 时 间(月）" name="statisticsEndTime">
              <a-date-picker v-model:value="formGeren.statisticsEndTime"  format="yyyy-MM" @change="onPickTime1"/>
            </a-form-item>
          </div>
          <div class="contents">
            <a-button @click="onSubmit">确认提交</a-button>
          </div>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from 'vue'
import 'moment/locale/zh-cn'
import axios from '@/api/axios'


export default defineComponent({

  name: "financialInstitution",

  setup() {

    return {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },


    }
  },
  data() {
    return {
      formGeren: {
        /**平台编码*/
        settlingPlatform: "",
        /**注册企业数*/
        registeredEnterpriseNum: "",
        /**授信企业数*/
        creditEnterpriseNum: "",
        /**获贷企业数*/
        loanEnterpriseNum: "",
        /**获贷小微企业数 */
        loanMicroEnterpriseNum: "",
        /** 授信总额*/
        creditAmount: "",
        /**放款总额*/
        loanAmount: "",
        /**普惠小微贷款总额*/
        inclusiveMicroEnterpriseloanAmount: "",
        /**放款笔数*/
        loanNum: "",
        /**信用放款总额 */
        creditLoanAmount: "",
        /**信用放款笔数*/
        creditLoanNum: "",
        /**融资需求金额*/
        financingNeedsAmount: "",
        /**融资需求笔数 */
        financingNeedsNum: "",
        /**放款平均利率*/
        averageLendingRate: "",
        /**入驻金融机构数 */
        settledInFinancialInstitutionNum: "",
        /**发布金融产品数*/
        financialProductsNum: "",
        /**当月放款金额 */
        monthLoanAmount: "",
        /**当月放款笔数*/
        monthLoanNum: "",
        /**当月信用放款金额*/
        monthCreditLoanAmount: "",
        /**当月信用放款笔数*/
        monthCreditLoanNum: "",
        /**当月新增普惠小微贷款金额*/
        monthCreditLoanEnterpriseNum: "",
        /**当月融资需求金额
         */
        monthFinancingNeedsAmount: "",
        /**当月融资需求笔数*/
        monthFinancingNeedsNum: "",
        /**统计开始时间*/
        statisticsBeginTime: "",
        /**统 计 截 止 时 间(月） */
        statisticsEndTime: "",

        /**省份*/
        province: "",
        /**城市*/
        city: "",
        /**区县*/
        district: "",
        /**行政区划代码*/
        areaCode: "",
        /**金融机构全称 */
        financeOrgFullName: "",
        /**银行业金融机构法人编码 */
        code: "",
      },

    }
  },
  methods: {
    onPickTime (date, dateString) {

      this.formGeren.statisticsBeginTime = dateString
    },
    onPickTime1 (date, dateString) {
      this.formGeren.statisticsEndTime = dateString
    },

    // 提交发布产品
    onSubmit() {
      let a = this.formGeren
      console.log(a)
      this.$store.dispatch('institutionFinancing', a).then(res => {
        if (res.result === 'success') {
          this.$message.success('成功！')
        } else {
          this.$message.info('系统异常！')
        }
      })
    },
  },

})
</script>

<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead) {
  background: -webkit-linear-gradient(to left, @color-blue, @color-purple); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(to left, @color-blue, @color-purple); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(to left, @color-blue, @color-purple); /* Firefox 3.6-15 */
  background: linear-gradient(to left, @color-blue, @color-purple); /* 标准语法 */
}

.content-box ::v-deep(.ant-table-thead th) {
  background-color: transparent;
  color: @color-ff;
}

.content-box {
  text-align: center;

  ::v-deep(.ant-form-item-control-wrapper) {
    margin: 0 auto;
    text-align: center;
  }

  .ant-btn {
    padding: 0 15px;
    height: 40px;
    color: @color-ff;
    margin: 0 5px;
    background: -webkit-linear-gradient(to left, @color-blue, @color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left, @color-blue, @color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left, @color-blue, @color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left, @color-blue, @color-purple); /* 标准语法 */
  }
}
</style>
<style lang="less" scoped>
.main-box {
  width: @main-width-base;
  margin: 20px auto;

  .ec-box {
    padding: 15px;
    background-color: @color-ff;
    box-shadow: 0px 0px 10px #929292;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;

    .title {
      font-size: @font-lg;
      padding: 0px 10px;
      padding-bottom: 15px;
      border-bottom: @border-base;
      display: flex;

      .tab {
        padding: 2px 15px;
        color: #A7A7A7;
        cursor: pointer;
      }

      .tab-active {
        padding: 2px 15px;
        color: #ffffff;
        cursor: pointer;
        background: -webkit-linear-gradient(to left, @color-blue, @color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to left, @color-blue, @color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to left, @color-blue, @color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to left, @color-blue, @color-purple); /* 标准语法 */
      }
    }

    .content {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .con-item {
        width: 49%;
      }

      .con-item-download {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        a {
          font-size: @font-lg;
          display: inline-block;
          text-decoration: underline;
          color: @color-ff;
          padding: 10px 60px;
          background: -webkit-linear-gradient(to left, @color-blue, @color-purple); /* Safari 5.1-6.0 */
          background: -o-linear-gradient(to left, @color-blue, @color-purple); /* Opera 11.1-12.0 */
          background: -moz-linear-gradient(to left, @color-blue, @color-purple); /* Firefox 3.6-15 */
          background: linear-gradient(to left, @color-blue, @color-purple); /* 标准语法 */


        }
      }

      .con-item-vercode {
        .ant-form-item {
          .ant-input {
            width: 55%;
          }

          .ant-btn {
            width: 40%;
            margin-left: 5%;
          }
        }
      }

      .con-item-upload {
        width: 49%;

        ::v-deep(.ant-upload) {
          width: 100%;
        }

        ::v-deep(.ant-btn) {
          width: 100%;
        }
      }

      .con-item-uploadimg {
        width: 30%;

        ::v-deep(.ant-upload) {
          width: 240px;
          height: 195px;
        }

        ::v-deep(.ant-upload-list-item) {
          width: 240px;
          height: 195px;
        }

        ::v-deep(.ant-upload-list-picture-card-container) {
          height: 195px;
        }
      }

      .contents {
        margin: 0 auto;

        ::v-deep(.ant-form-item-control-wrapper) {
          margin: 0 auto;
          text-align: center;
        }

        .ant-btn {
          padding: 0 120px;
          height: 40px;
          color: @color-ff;
          background: -webkit-linear-gradient(to left, @color-blue, @color-purple); /* Safari 5.1-6.0 */
          background: -o-linear-gradient(to left, @color-blue, @color-purple); /* Opera 11.1-12.0 */
          background: -moz-linear-gradient(to left, @color-blue, @color-purple); /* Firefox 3.6-15 */
          background: linear-gradient(to left, @color-blue, @color-purple); /* 标准语法 */
        }
      }
    }
  }

  .ec-box::before {
    content: '';
    position: absolute;
    left: -3px;
    top: 10px;
    width: 6px;
    height: 40px;
    background: -webkit-linear-gradient(to top, @color-blue, @color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to top, @color-blue, @color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to top, @color-blue, @color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to top, @color-blue, @color-purple); /* 标准语法 */
  }
}
</style>
